import React, { Component } from "react";
import Dialog from "../Dialog";
import { connect } from "react-redux";
import ActionCreator from "../../ActionCreator";
import Selectors from "../../Selectors";
import * as Unstated from "../../Utils/Unstated";
import * as Widget from "../Widget";
import * as Icon from "../Icon";
import * as L from "../../Utils/Lang";
import { FacebookLoginButton, GoogleLoginButton } from "../SocialLoginButtons";

const UiState = {
  LOGIN: "LOGIN",
  REGISTER: "REGISTER",
  RESET_PASSWORD: "RESET_PASSWORD",
  VALIDATE_ACCOUNT: "VALIDATE_ACCOUNT"
};

class LoginDialog extends Component {
  state = {
    uiState: UiState.LOGIN
  };

  render() {
    let { uiState } = this.state;

    switch (uiState) {
      case UiState.LOGIN:
        return this._renderLogin();
      case UiState.REGISTER:
        return this._renderRegister();
      case UiState.RESET_PASSWORD:
        return this._renderResetPassword();
      case UiState.VALIDATE_ACCOUNT:
        return this._renderValidateAccount();
      default:
        return null;
    }
  }

  _renderLogin = () => {
    let { onClose, appActions } = this.props;

    return (
      <Dialog>
        <Unstated.Form
          key="login"
          init={() => ({
            values: { username: "", password: "" },
            editing: true
          })}
          submit={values => {
            if (!values.username || !values.password) {
              return Promise.resolve({
                values,
                error: L.s("all-fields-required")
              });
            }

            let { username, password } = values;
            return appActions
              .login({ username, password })
              .then(onClose)
              .then(() =>
                appActions.ga.trackingUserAction("User", "login", "success")
              )
              .catch(err => {
                if (err.status === 401) {
                  appActions.ga.trackingUserAction("User", "login", "fail 401");
                  return { error: L.s("error-login-message") };
                }
                if (err.status === 491) {
                  appActions.ga.trackingUserAction("User", "login", "fail 491");
                  return this.setState({
                    uiState: UiState.VALIDATE_ACCOUNT,
                    username,
                    password
                  });
                }
                appActions.ga.trackingUserAction(
                  "User",
                  "login",
                  "fail others"
                );
                return { error: L.s("error-message") };
              });
          }}
        >
          {({ values, errors, inputProps, editProps, submitProps }) => (
            <div style={{ padding: 20 }}>
              <h2
                style={{
                  marginBottom: 10,
                  textAlign: "center",
                  borderBottom: "1px solid lightgrey",
                  color: "#444"
                }}
              >
                LOGIN
              </h2>

              <Widget.MaxWidth width="400">
                <Widget.Col align="'center">
                  <FacebookLoginButton />
                  <GoogleLoginButton css="margin-top: 10px;" />
                </Widget.Col>

                <Widget.Row align="center">
                  <div
                    style={{
                      flexGrow: 1,
                      borderTop: "2px solid #eee"
                    }}
                  />
                  <p style={{ padding: 10, color: "#eee" }}>OR</p>
                  <div
                    style={{
                      flexGrow: 1,
                      borderTop: "2px solid #eee"
                    }}
                  />
                </Widget.Row>

                <Widget.Row
                  extraCss="position: relative;margin-bottom:10px;"
                  align="center"
                >
                  <Icon.Email
                    color="#ccc"
                    style={{ position: "absolute", left: 10 }}
                  />
                  <Widget.Input
                    placeholder={L.s("email")}
                    {...inputProps.username}
                    extraCss="padding: 10px;padding-left: 40px;"
                  />
                </Widget.Row>

                <Widget.Row extraCss="position: relative;" align="center">
                  <Icon.Lock
                    color="#ccc"
                    style={{ position: "absolute", left: 10 }}
                  />
                  <Widget.Input
                    placeholder={L.s("password")}
                    type="password"
                    {...inputProps.password}
                    extraCss="padding: 10px;padding-left: 40px;"
                  />
                </Widget.Row>

                <span
                  style={{
                    display: "block",
                    marginTop: 10,
                    color: "#00bcd4",
                    fontSize: 14,
                    cursor: "pointer"
                  }}
                  onClick={() =>
                    this.setState({ uiState: UiState.RESET_PASSWORD })
                  }
                >
                  {L.s("forget-password")}
                </span>

                <div style={{ marginTop: 10 }}>
                  {submitProps.submitError && (
                    <div
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginBottom: 5
                      }}
                    >
                      {submitProps.submitError}
                    </div>
                  )}
                  {submitProps.submitting ? (
                    <Widget.Center>
                      <Widget.Spinner />
                    </Widget.Center>
                  ) : (
                    <Widget.Row justify="center">
                      <Widget.Button
                        label={L.s("cancel")}
                        style={{ height: 40, width: 300 }}
                        onClick={onClose}
                      />
                      <Widget.Button
                        label={L.s("login")}
                        labelStyle={{ color: "white" }}
                        onClick={submitProps.submit}
                        disabled={
                          !submitProps.enabled || submitProps.submitting
                        }
                        backgroundColor="#00bcd4"
                        style={{ height: 40, width: 300 }}
                      />
                    </Widget.Row>
                  )}
                </div>

                <Widget.Center
                  extraCss={`
                                            margin-top: 20px;
                                            padding: 10px;
                                            border-top: 2px solid #eee;
                                            font-size: 14px;
                                        `}
                >
                  <p>
                    {L.s("not-member")}{" "}
                    <span
                      style={{ color: "#00bcd4", cursor: "pointer" }}
                      onClick={() =>
                        this.setState({ uiState: UiState.REGISTER })
                      }
                    >
                      {L.s("register")}
                    </span>
                  </p>
                </Widget.Center>
              </Widget.MaxWidth>
            </div>
          )}
        </Unstated.Form>
      </Dialog>
    );
  };

  _renderRegister = () => {
    let { onClose, appActions } = this.props;

    return (
      <Dialog>
        <Unstated.Form
          key="register"
          init={() => ({
            values: {
              email: "",
              password: ""
            },
            editing: true
          })}
          submit={values => {
            if (!values.email || !values.password) {
              return Promise.resolve({
                values,
                error: L.s("all-fields-required")
              });
            }

            let { email, password } = values;
            let username = email;
            return appActions
              .register({ username, email, password })
              .then(() => appActions.login({ username, password }))
              .then(onClose)
              .catch(err => {
                if (err.status === 491) {
                  return this.setState({
                    uiState: UiState.VALIDATE_ACCOUNT,
                    username,
                    password
                  });
                }
                if (err.status === 492) {
                  return { error: L.s("email-used") };
                }
                if (err.status === 493) {
                  return { error: L.s("email-used") };
                }
                return { error: L.s("error-register-message") };
              });
          }}
        >
          {({ values, errors, inputProps, editProps, submitProps }) => (
            <div style={{ padding: 20 }}>
              <h2
                style={{
                  marginBottom: 10,
                  textAlign: "center",
                  borderBottom: "1px solid lightgrey",
                  color: "#444"
                }}
              >
                REGISTER
              </h2>

              <Widget.MaxWidth width="400">
                <Widget.Row
                  extraCss="position: relative;margin-bottom:10px;"
                  align="center"
                >
                  <Icon.Email
                    color="#ccc"
                    style={{ position: "absolute", left: 10 }}
                  />
                  <Widget.Input
                    placeholder={L.s("email")}
                    {...inputProps.email}
                    extraCss="padding: 10px;padding-left: 40px;"
                  />
                </Widget.Row>

                <Widget.Row extraCss="position: relative;" align="center">
                  <Icon.Lock
                    color="#ccc"
                    style={{ position: "absolute", left: 10 }}
                  />
                  <Widget.Input
                    placeholder={L.s("password")}
                    type="password"
                    {...inputProps.password}
                    extraCss="padding: 10px;padding-left: 40px;"
                  />
                </Widget.Row>

                <div style={{ marginTop: 20 }}>
                  {submitProps.submitError && (
                    <div
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginBottom: 5
                      }}
                    >
                      {submitProps.submitError}
                    </div>
                  )}
                  {submitProps.submitting ? (
                    <Widget.Center>
                      <Widget.Spinner />
                    </Widget.Center>
                  ) : (
                    <Widget.Row justify="center">
                      <Widget.Button
                        label={L.s("cancel")}
                        style={{ height: 40, width: 300 }}
                        onClick={onClose}
                      />
                      <Widget.Button
                        label={L.s("register")}
                        labelStyle={{ color: "white" }}
                        onClick={submitProps.submit}
                        disabled={
                          !submitProps.enabled || submitProps.submitting
                        }
                        backgroundColor="#00bcd4"
                        style={{ height: 40, width: 300 }}
                      />
                    </Widget.Row>
                  )}
                </div>

                <Widget.Center
                  extraCss={`
                                            margin-top: 20px;
                                            padding: 10px;
                                            border-top: 2px solid #eee;
                                            font-size: 14px;
                                        `}
                >
                  <p>
                    {L.s("already-member")}{" "}
                    <span
                      style={{ color: "#00bcd4", cursor: "pointer" }}
                      onClick={() => this.setState({ uiState: UiState.LOGIN })}
                    >
                      {L.s("login")}
                    </span>
                  </p>
                </Widget.Center>
              </Widget.MaxWidth>
            </div>
          )}
        </Unstated.Form>
      </Dialog>
    );
  };

  _renderResetPassword = () => {
    let { onClose, appActions } = this.props;

    return (
      <Dialog>
        <div style={{ padding: 20 }}>
          <h2
            style={{
              marginBottom: 10,
              textAlign: "center",
              borderBottom: "1px solid lightgrey",
              color: "#444"
            }}
          >
            RESET PASSWORD
          </h2>

          <Unstated.WithState state={{ resetPasswordSuccess: false }}>
            {({ state, setState }) => {
              let { resetPasswordSuccess } = state;

              if (resetPasswordSuccess === true) {
                return (
                  <Widget.MaxWidth width="400">
                    <div>{L.s("reset-password-success-message")}</div>

                    <Widget.Center
                      extraCss={`
                                                    margin-top: 20px;
                                                    padding: 10px;
                                                    border-top: 2px solid #eee;
                                                    font-size: 14px;
                                                `}
                    >
                      <p>
                        {L.s("back-to")}{" "}
                        <span
                          style={{ color: "#00bcd4", cursor: "pointer" }}
                          onClick={() =>
                            this.setState({ uiState: UiState.LOGIN })
                          }
                        >
                          {L.s("login")}
                        </span>
                      </p>
                    </Widget.Center>
                  </Widget.MaxWidth>
                );
              }

              return (
                <Unstated.Form
                  key="reset-password"
                  init={() => ({
                    values: { email: "" },
                    editing: true
                  })}
                  submit={values => {
                    if (!values.email) {
                      return Promise.resolve({
                        values,
                        error: L.s("all-fields-required")
                      });
                    }

                    let { email } = values;
                    return appActions
                      .resetPassword({ email })
                      .then(() => setState({ resetPasswordSuccess: true }))
                      .catch(err => {
                        if (err.status === 404) {
                          return { error: L.s("email-not-found") };
                        }
                        return { error: L.s("error-reset-password-message") };
                      });
                  }}
                >
                  {({ values, errors, inputProps, editProps, submitProps }) => (
                    <Widget.MaxWidth width="400">
                      <Widget.Row extraCss="position: relative;" align="center">
                        <Icon.Email
                          color="#ccc"
                          style={{ position: "absolute", left: 10 }}
                        />
                        <Widget.Input
                          placeholder={L.s("email")}
                          {...inputProps.email}
                          extraCss="padding: 10px;padding-left: 40px;"
                        />
                      </Widget.Row>

                      <div style={{ marginTop: 20 }}>
                        {submitProps.submitError && (
                          <div
                            style={{
                              color: "red",
                              textAlign: "center",
                              marginBottom: 5
                            }}
                          >
                            {submitProps.submitError}
                          </div>
                        )}
                        {submitProps.submitting ? (
                          <Widget.Center>
                            <Widget.Spinner />
                          </Widget.Center>
                        ) : (
                          <Widget.Row justify="center">
                            <Widget.Button
                              label={L.s("cancel")}
                              style={{ height: 40, width: 300 }}
                              onClick={onClose}
                            />
                            <Widget.Button
                              label={L.s("reset-password")}
                              labelStyle={{ color: "white" }}
                              onClick={submitProps.submit}
                              disabled={
                                !submitProps.enabled || submitProps.submitting
                              }
                              backgroundColor="#00bcd4"
                              style={{ height: 40, width: 300 }}
                            />
                          </Widget.Row>
                        )}
                      </div>

                      <Widget.Center
                        extraCss={`
                                                            margin-top: 20px;
                                                            padding: 10px;
                                                            border-top: 2px solid #eee;
                                                            font-size: 14px;
                                                        `}
                      >
                        <p>
                          {L.s("back-to")}{" "}
                          <span
                            style={{ color: "#00bcd4", cursor: "pointer" }}
                            onClick={() =>
                              this.setState({ uiState: UiState.LOGIN })
                            }
                          >
                            {L.s("login")}
                          </span>
                        </p>
                      </Widget.Center>
                    </Widget.MaxWidth>
                  )}
                </Unstated.Form>
              );
            }}
          </Unstated.WithState>
        </div>
      </Dialog>
    );
  };

  _renderValidateAccount = () => {
    let { onClose, appActions } = this.props;
    let { username, password } = this.state;

    return (
      <Dialog>
        <div style={{ padding: 20 }}>
          <h2
            style={{
              marginBottom: 10,
              textAlign: "center",
              borderBottom: "1px solid lightgrey",
              color: "#444"
            }}
          >
            VALIDATION
          </h2>

          <Unstated.WithState
            state={{
              resetValidationSuccess: false,
              submitting: false,
              errMsg: ""
            }}
          >
            {({ state, setState }) => {
              let { resetValidationSuccess, submitting, errMsg } = state;

              function _submit() {
                setState({ submitting: true });
                appActions
                  .resetValidation({ username, password })
                  .then(() =>
                    setState({
                      resetValidationSuccess: true,
                      submitting: false
                    })
                  )
                  .catch(() =>
                    setState({
                      submitting: false,
                      errMsg: L.s("err-reset-validation-message")
                    })
                  );
              }

              return (
                <Widget.MaxWidth width="400">
                  {(() => {
                    if (resetValidationSuccess === true) {
                      return (
                        <div>{L.s("reset-validation-success-message")}</div>
                      );
                    }

                    return <div>{L.s("reset-validation-message")}</div>;
                  })()}
                  <div style={{ marginTop: 20 }}>
                    {errMsg && (
                      <div
                        style={{
                          color: "red",
                          textAlign: "center",
                          marginBottom: 5
                        }}
                      >
                        {errMsg}
                      </div>
                    )}
                    {submitting ? (
                      <Widget.Center>
                        <Widget.Spinner />
                      </Widget.Center>
                    ) : (
                      <Widget.Row justify="center">
                        <Widget.Button
                          label={L.s("cancel")}
                          style={{ height: 40, width: 300 }}
                          onClick={onClose}
                        />
                        <Widget.Button
                          label={L.s("reset-validation")}
                          labelStyle={{ color: "white" }}
                          onClick={_submit}
                          disabled={submitting}
                          backgroundColor="#00bcd4"
                          style={{ height: 40, width: 300 }}
                        />
                      </Widget.Row>
                    )}
                  </div>

                  <Widget.Center
                    extraCss={`
                                                margin-top: 20px;
                                                padding: 10px;
                                                border-top: 2px solid #eee;
                                                font-size: 14px;
                                            `}
                  >
                    <p>
                      {L.s("back-to")}{" "}
                      <span
                        style={{ color: "#00bcd4", cursor: "pointer" }}
                        onClick={() =>
                          this.setState({ uiState: UiState.LOGIN })
                        }
                      >
                        {L.s("login")}
                      </span>
                    </p>
                  </Widget.Center>
                </Widget.MaxWidth>
              );
            }}
          </Unstated.WithState>
        </div>
      </Dialog>
    );
  };
}

LoginDialog = connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state)
  }),
  ActionCreator
)(LoginDialog);

const Login = ({ renderLogin, onClick }) => {
  if (!renderLogin) {
    return <Widget.Button label={L.s("login")} onClick={onClick} />;
  }

  return renderLogin({ onClick });
};

class LoginButton extends Component {
  render() {
    let { profile, renderLogin } = this.props;

    return (
      <Unstated.Toggle>
        {({ enable, setEnable }) => (
          <div>
            {enable && <LoginDialog onClose={() => setEnable(false)} />}
            {profile ? (
              <Widget.Button label={L.s("logout")} onClick={this._logout} />
            ) : (
              <Login
                onClick={() => setEnable(true)}
                renderLogin={renderLogin}
              />
            )}
          </div>
        )}
      </Unstated.Toggle>
    );
  }

  _logout = () => {
    let { appActions } = this.props;
    appActions.logout();
  };
}

LoginButton = connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(LoginButton);

export default {
  Dialog: LoginDialog,
  Button: LoginButton
};
