import React, { Component } from 'react';
import * as Widget from './Widget';
import ActionCreator from '../ActionCreator';
import { connect } from 'react-redux';

class EcPayCheckoutButton extends Component {
  render() {
    let { order } = this.props;
    return <div>{order && this._renderEcPayButton(order)}</div>;
  }

  _renderEcPayButton = order => {
    function submit() {
      document.getElementById('_form_aiochk').submit();
    }

    let form = order.payment_transaction_detail_1;
    if (!form) {
      return null;
    }

    return (
      <div style={{ textAlign: 'center' }}>
        <div
          style={{ display: 'none' }}
          dangerouslySetInnerHTML={{ __html: form }}
        />
        <Widget.FlatButton
          label="ECPAY Checkout"
          primary={true}
          backgroundColor="#FAFAFA"
          labelStyle={{ fontSize: '1.2em' }}
          style={{
            lineHeight: 'normal',
            border: '3px solid',
            marginTop: 5,
            marginBottom: 5,
          }}
          hoverColor="white"
          onClick={submit}
        />
      </div>
    );
  };
}

export default connect(
  null,
  ActionCreator
)(EcPayCheckoutButton);
