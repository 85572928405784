import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ActionCreator from '../ActionCreator';
import Constants from '../Domain/constants';
import PayPalCheckoutButton from '../Components/PaypalCheckoutButton';
import EcPayCheckoutButton from '../Components/EcPayCheckoutButton';

export const PAYMENT_TYPE = {
  ECPAY: 'ecpay',
  ECPAY_CVS: 'ecpay_cvs',
  PAYPAL: 'paypal',
  OFFLINE: 'offline',
};

class PaymentButton extends React.Component {
  render() {
    let { order, extraCss = '' } = this.props;

    return (
      <Wrapper extraCss={extraCss}>
        {order.payment_type === PAYMENT_TYPE.ECPAY && (
          <EcPayCheckoutButton order={order} />
        )}

        {order.payment_type === PAYMENT_TYPE.PAYPAL && (
          <PayPalCheckoutButton
            order={order}
            onStart={() => {
              this.setState({ status: 'Payment processing' });
              return Promise.resolve();
            }}
            onSuccess={this._paypalOnSuccess}
            onFailure={this._paypalOnFailure}
            onCancel={() => this.setState({ status: '' })}
          />
        )}

        {order.payment_type === PAYMENT_TYPE.ECPAY_CVS && null}

        {order.payment_type === PAYMENT_TYPE.OFFLINE && (
          <div>請至如下銀行轉帳：xxxx</div>
        )}
      </Wrapper>
    );
  }

  _paypalOnSuccess = (id, data) => {
    let { appActions, navActions } = this.props;
    appActions.paypalOnSuccess(id, data).then(result => {
      navActions.push(result.redirect_url.replace(Constants.webUrl, ''));
    });
  };

  _paypalOnFailure = error => {
    console.log('paypal failure', error);
    this.setState({ status: 'Payment failure' });
  };
}

let Wrapper = styled.div`
  ${props => props.extraCss};
`;

export default connect(
  null,
  ActionCreator
)(PaymentButton);
