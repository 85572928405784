import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Selectors from '../Selectors';
import ActionCreator from '../ActionCreator';
import * as Unstated from '../Utils/Unstated';
import * as Widget from './Widget';

export const basicProfile = ['name', 'email', 'address', 'phone'];

class Profile extends React.Component {
  initialMessageState = {
    display: false,
    failure: false,
    text: '',
  };

  state = {
    message: { ...this.initialMessageState },
  };

  render() {
    let { extraCss, profile, checkout, values, onChange } = this.props;
    let { message } = this.state;

    if (checkout) {
      return (
        <CheckoutWrapper extraCss={extraCss}>
          <CheckoutForm>
            {basicProfile.map(v => {
              return (
                <CheckoutField key={v}>
                  <h3>{v}</h3>
                  <Widget.Input
                    value={values[v]}
                    onChange={e => onChange({ [v]: e.target.value })}
                  />
                </CheckoutField>
              );
            })}
            <div style={{ minWidth: 220, alignSelf: 'flex-end' }}>
              <Widget.Checkbox
                checked={values.update_profile}
                label="同步更新購買人資料"
                onCheck={() =>
                  onChange({ update_profile: !values.updateProfile })
                }
              />
            </div>
          </CheckoutForm>
        </CheckoutWrapper>
      );
    }

    return (
      <Wrapper extraCss={extraCss}>
        <Unstated.Form
          init={() => ({
            values: basicProfile.reduce(
              (acc, v, i) => ({ ...acc, [v]: profile[v] || '' }),
              {}
            ),
            editing: true,
          })}
          submit={this._submitForm}
        >
          {({ values, errors, editProps, inputProps, submitProps }) => {
            return (
              <Form>
                {basicProfile.map(v => {
                  return (
                    <Input key={v}>
                      <h3>{v}</h3>
                      <input
                        {...inputProps[v]}
                        onFocus={() =>
                          this.setState({
                            message: { ...this.initialMessageState },
                          })
                        }
                      />
                    </Input>
                  );
                })}
                {message.display && (
                  <Message failure={message.failure}>{message.text}</Message>
                )}
                {submitProps.submitting ? (
                  <UpdateButtonSpinnerContainer>
                    <Widget.Spinner color="white" />
                  </UpdateButtonSpinnerContainer>
                ) : (
                  <UpdateButton
                    disabled={this._isProfileChanged(values)}
                    onClick={submitProps.submit}
                  >
                    更新
                  </UpdateButton>
                )}
              </Form>
            );
          }}
        </Unstated.Form>
      </Wrapper>
    );
  }

  _isProfileChanged = values => {
    let { profile } = this.props;

    return basicProfile.every((v, i) => values[v] === profile[v]);
  };

  _submitForm = values => {
    let { appActions } = this.props;

    return appActions
      .editMyProfile(values)
      .then(() =>
        this.setState({ message: { display: true, text: '更新成功！' } })
      )
      .catch(() =>
        this.setState({
          message: {
            display: true,
            failure: true,
            text: '更新失敗！請再試一次或聯絡網站人員。',
          },
        })
      );
  };
}

const Wrapper = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props => props.extraCss || ''};
`;

const Form = styled.div`
  width: 620px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

const Input = styled.div`
  margin-bottom: 20px;
  border: solid 1px #e2e4e5;
  border-radius: 4px;
  padding: 10px 20px;

  & > h3 {
    margin-bottom: 6px;
    font-size: 18px;
    color: rgba(52, 62, 65, 0.25);
  }

  & > input {
    width: 100%;
    border: solid 1px #e2e4e5;
    border-radius: 3px;
    padding: 6px 8px;
    background-color: #fafbfc;
    font-size: 16px;
    color: #343e41;
  }

  ${props => props.extraCss || ''};
`;

const Message = styled.div`
  align-self: flex-end;
  margin-bottom: 6px;
  font-size: 13px;
  color: ${props => (props.failure ? 'red' : 'blue')};
`;

const UpdateButton = styled(Widget.BaseButton)`
  width: 160px;
  border-radius: 4px;
  padding: 12px 0;
  background-color: #00aff0;
  align-self: flex-end;
  font-size: 20px;
  color: white;

  ${props => props.extraCss || ''};
`;

const UpdateButtonSpinnerContainer = styled.div`
  width: 160px;
  height: 52px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00aff0;
  align-self: flex-end;
`;

const CheckoutWrapper = styled.div`
  display: flex;
  justify-content: center;

  ${props => props.extraCss || ''};
`;

const CheckoutForm = styled.div`
  width: 400px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

const CheckoutField = styled.div`
  margin-bottom: 7px;

  & > h3 {
    font-size: 18px;
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(Profile);
