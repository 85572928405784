import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ActionCreator from '../ActionCreator';
import { dateParser } from '../Utils/DateUtil';
import * as L from '../Utils/Lang';
import * as Widget from '../Components/Widget';
import PaymentButton from '../Components/PaymentButton';

class OrderItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetail: false,
    };
  }

  render() {
    let { extraCss, order, detail = false, children } = this.props;
    let { showDetail } = this.state;

    if (detail) {
      return (
        <DetailWrapper extraCss={extraCss}>
          <Date>{dateParser(order.created)}</Date>
          <Title>{order.title}</Title>
          {children}
          <Widget.Col
            extraCss={`${children &&
              `border-top: 1px dashed grey;`}margin-top: 5px;`}
          >
            <Widget.Row extraCss="margin-top: 5px;">
              <DataTitle>付款方式</DataTitle>
              <DataValue>{L.s(`payment-type-${order.payment_type}`)}</DataValue>
            </Widget.Row>
          </Widget.Col>

          {!showDetail ? (
            <Widget.Button
              style={{ margin: '10px 0px' }}
              label={'查看訂單細目 + '}
              onClick={() => this.setState({ showDetail: true })}
            />
          ) : (
            <div>
              <Widget.Button
                style={{ margin: '10px 0px' }}
                label={'關閉訂單細目 - '}
                onClick={() => this.setState({ showDetail: false })}
              />
              <div style={{ marginBottom: 10 }}>{order.meta}</div>
            </div>
          )}

          <div>
            {order.payment_status === 'failure' && (
              <HintText>{`付款失敗！請 聯絡客服 或 重新下訂！`}</HintText>
            )}

            {order.payment_status === 'waiting' && (
              <PaymentButton
                order={order}
                extraCss="display: flex; justify-content: center;"
              />
            )}
          </div>

          <Widget.Row align="flex-end" justify="flex-end">
            <Price extraCss="margin-right: 10px;">
              {order.currency} {order.amount}
            </Price>
            <Status
              success={order.payment_status === 'success'}
              failure={order.payment_status === 'failure'}
            >
              {L.s(`payment-${order.payment_status}`)}
            </Status>
          </Widget.Row>
        </DetailWrapper>
      );
    }
    return (
      <Wrapper
        extraCss={extraCss}
        to={`/order?id=${order.id}`}
        success={order.payment_status === 'success'}
        failure={order.payment_status === 'failure'}
      >
        <Date>{dateParser(order.created)}</Date>
        <Title>{order.title}</Title>
        <Widget.Row extraCss="margin-top: 5px;">
          <div style={{ marginRight: 5 }}>付款方式</div>
          <div>{L.s(`payment-type-${order.payment_type}`)}</div>
        </Widget.Row>

        <Widget.Row align="flex-end" justify="flex-end">
          <Price extraCss="margin-right: 5px;">
            {order.currency} {order.amount}
          </Price>
          <Status
            success={order.payment_status === 'success'}
            failure={order.payment_status === 'failure'}
          >
            {L.s(`payment-${order.payment_status}`)}
          </Status>
        </Widget.Row>
      </Wrapper>
    );
  }

  _parse = data => {
    let result = null;
    try {
      result = JSON.parse(data);
    } catch (err) {
      console.warn('parse order meta failed');
    }
    return result;
  };
}

let Wrapper = styled(Link)`
  display: block;
  padding: 10px;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 0 13px 0 #e0e0e0;
  border-left: 5px solid grey;
  color: black;
  text-decoration: none;
  ${props => props.extraCss};
  ${props => props.success && `border-color: #00ce6b;`} ${props =>
    props.failure && `border-color: tomato;`};
`;

let Date = styled.div`
  color: grey;
  font-size: 12px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
`;

let Title = styled.h3``;

let Price = styled.div`
  color: orange;
  font-weight: 600;
  ${props => props.extraCss};
`;

let Status = styled.div`
  background-color: grey;
  padding: 5px;
  color: white;
  ${props =>
    props.success &&
    `
        background-color: #00ce6b;
    `} ${props =>
    props.failure &&
    `
        background-color: tomato;
    `};
`;

let DetailWrapper = styled.div`
  position: relative;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 13px 0 #e0e0e0;
  ${props => props.extraCss};
`;

let DataTitle = styled.div`
  margin-right: 5px;
`;

let DataValue = styled.div``;

let HintText = styled.div`
  color: tomato;
  text-align: center;
  padding: 5px;
`;

export default connect(
  null,
  ActionCreator
)(OrderItem);
